import { GetInstances, GetResponse } from './api.models';
import { API } from './api';
import { Product, StoreCategory, SupplerApiServices, Supplier } from '../components/store/store.models';
import { Purchase, PurchaseStatus, UpdatePurchaseStatus } from '../components/orders/order.models';

export const getProductsAPI = async <T>(
    getBy: GetInstances<T>
): Promise<GetResponse & { data: Product[] }> => {
    return API.get({ params: getBy, url: `store/products` });
};

export const getSearchProductsVariantsAPI = async (name: string): Promise<Product[]> => {
    return API.get({ url: `store/products/variants-search/${name}` });
};

export const getCategoriesAPI = async <T>(
    getBy: GetInstances<T>
): Promise<GetResponse & { data: StoreCategory[] }> => {
    return API.get({ url: `store/categories`, params: getBy });
};

export const getCategoryByIDAPI = async (categoryID: string): Promise<StoreCategory> => {
    return API.get({ url: `store/category/${categoryID}` });
};

export const deleteCategoryAPI = async (categoryID: string): Promise<StoreCategory> => {
    return API.delete({ url: `store/categories/${categoryID}` });
};

export const createOrEditCategoryAPI = async (category: StoreCategory): Promise<StoreCategory> => {
    return API.post({ url: `store/categories`, body: category });
};

export const variantsOptionsAPI = async (): Promise<{ variantID: string; name: string }[]> => {
    return API.get({ url: `store/products/variants-options` });
};

export const getOrderStatusesAPI = async (): Promise<PurchaseStatus[]> => {
    return API.get({ url: `store/statuses/public` });
};

export const getInternalOrderStatusesAPI = async (): Promise<PurchaseStatus[]> => {
    return API.get({ url: `store/statuses/internal` });
};

export const createOrderStatusAPI = async (orderStatus: PurchaseStatus): Promise<void> => {
    return API.post({ url: `store/statuses`, body: orderStatus });
};

export const deleteOrderStatusAPI = async (purchaseStatusID: string): Promise<void> => {
    return API.delete({ url: `store/statuses/${purchaseStatusID}` });
};

export const deleteVariantAPI = async (variantID: string): Promise<{ variantID: string; name: string }[]> => {
    return API.delete({ url: `store/variants/${variantID}` });
};

export const createVariantAPI = async (name: string): Promise<{ variantID: string; name: string }[]> => {
    return API.post({ url: `store/variant`, body: { name } });
};

export const getPurchaseByID = async (purchaseID: string): Promise<Purchase> => {
    return API.get({ url: `store/purchases/${purchaseID}` });
};

export const getPurchasesAPI = async <T>(
    getBy?: GetInstances<T>
): Promise<GetResponse & { data: Purchase[] }> => {
    return API.get({ url: `store/purchases`, params: getBy });
};

export const getSuppliersAPI = async <T>(
    getBy?: GetInstances<T>
): Promise<GetResponse & { data: Supplier[] }> => {
    return API.get({ url: `store/suppliers`, params: getBy });
};

export const getSupplierByIDAPI = async (supplierID: string): Promise<Supplier> => {
    return API.get({ url: `store/supplier/${supplierID}` });
};

export const getSuppliersAPIServicesAPI = async (): Promise<SupplerApiServices[]> => {
    return API.get({ url: `store/suppliers/services` });
};

export const toggleHotSaleAPI = async (productID: string): Promise<void> => {
    return API.put({ url: `store/products/hotSale/${productID}` });
};

export const toggleShowOrHideProductAPI = async (productID: string): Promise<void> => {
    return API.put({ url: `store/products/hide/${productID}` });
};

export const toggleForSaleProductAPI = async (productID: string): Promise<void> => {
    return API.put({ url: `store/products/forsale/${productID}` });
};

export const createOrEditProductAPI = async (product: Product): Promise<void> => {
    return API.post({ url: `store/products`, body: product });
};

export const refundPurchase = async (purchaseID: string): Promise<Product> => {
    return API.put({ url: `store/purchase/refund/${purchaseID}` });
};

export const getDuplicateProductImagesAPI = async (
    productID: string
): Promise<{ mainImage: string; additionalImages: string[] }> => {
    return API.get({ url: `store/products/images/${productID}` });
};

export const toggleSupplierActiveAPI = async (supplierID: string): Promise<void> => {
    return API.put({ url: `store/suppliers/${supplierID}` });
};

export const deleteProductAPI = async (productID: string): Promise<void> => {
    return API.delete({ url: `store/products/${productID}` });
};

export const deleteSupplierAPI = async (supplierID: string): Promise<void> => {
    return API.delete({ url: `store/suppliers/${supplierID}` });
};

export const createOrEditSupplierAPI = async (supplier: Supplier): Promise<void> => {
    return API.post({ url: `store/suppliers`, body: supplier });
};

export const updatePurchaseStatusAPI = async (updatePurchaseStatus: UpdatePurchaseStatus) => {
    return API.put({ url: `store/purchase`, body: updatePurchaseStatus });
};

export const sendEmailToSuppliersTestAPI = async () => {
    return API.post({ url: `store/purchases/test-email` });
};

export const changePurchaseStatusAPI = async (
    purchasesIDs: string[],
    newStatus: string,
    statusType: 'regular' | 'internal'
) => {
    return API.put({ url: `store/purchase/many`, body: { purchasesIDs, newStatus, statusType } });
};

export const promotedProductAPI = async (body: any) => {
    return API.post({ url: `store/promotedCategory`, body });
};
