import { ReactElement } from 'react';
import { FilterForm } from '../table/Tables.types';
import { v4 } from 'uuid';
import { EditorState } from 'draft-js';
import { SelectOption } from '../../models/shared.models';
import { getRanHex } from './store.utils';

export const communiSupplierName = 'COMMUNi';

export const storeTabsNames = ['ניהול מוצרים', 'ניהול קטגוריות', 'ניהול ספקים'];

export type AvailabilitySupplier = 'available' | 'in_stock' | 'not_available';

export type Variant = {
    id: string;
    isMain: boolean;
    variants: { type: string; value: string }[];
};

export type Product = {
    productID: string;
    catalogNumberID: string;
    categoryID: string;
    categoryName: string;
    category?: { label: string; value: string };
    title: string;
    subtitle: string;
    brand_en: string;
    brand_he: string;
    summary: string;
    description: any;
    specifications?: any;
    mainImage: string;
    mainImageURL: string;
    additionalImages: string[];
    additionalImagesURLs: { imageURL: string; url: string }[];
    youtube?: string;
    variant?: Variant | null;
    originalPrice: number | '';
    supplierPrice: number | '';
    price: number | '';
    delivery_price: number | '';
    rating?: number | '';
    ratingCount?: number | '';
    availabilitySupplier: AvailabilitySupplier;
    isShown: boolean;
    isForSale: boolean;
    supplyInBusinessDays?: number | '';
    warranty: any;
    supplier: string;
    hotSale: boolean;
    enteredCatalogTime: number;
    isNew?: boolean;
    shownByCompany?: boolean;
    isDigital: boolean;
    items: any[];
    availableItemsCount: number;
};

export type ProductForm = Omit<Product, 'variant' | 'availabilitySupplier'> & {
    variant?: null | { id: string; isMain: boolean; variants: { value: string; type: SelectOption }[] };
    availabilitySupplier: boolean;
    isDigital: boolean;
    items: any[];
    availableItemsCount: number;
};

export type CategoryForm = StoreCategory & {
    url: string;
};

export const initialCategory = (): CategoryForm => {
    return {
        categoryID: '',
        supplierID: communiSupplierName,
        supplierCategoryName: '',
        categoryImageURL: '',
        url: '',
        categoryName: '',
        parentCategoryID: [],
        supplierCategoryID: '',
        promotedProduct: false,
        company: { label: '', value: '' },
        companiesUsingCategory: [],
    };
};

export const initialProduct = (): ProductForm => {
    return {
        productID: v4(),
        catalogNumberID: getRanHex(),
        categoryID: '',
        categoryName: '',
        category: { label: '', value: '' },
        title: '',
        subtitle: '',
        brand_en: '',
        brand_he: '',
        summary: '',
        description: EditorState.createEmpty(),
        specifications: EditorState.createEmpty(),
        warranty: EditorState.createEmpty(),
        mainImage: '',
        mainImageURL: '',
        additionalImages: [],
        additionalImagesURLs: [],
        youtube: '',
        variant: null,
        originalPrice: '',
        supplierPrice: '',
        price: '',
        delivery_price: '',
        rating: '',
        ratingCount: '',
        availabilitySupplier: true,
        isShown: true,
        isForSale: false,
        supplyInBusinessDays: '',
        supplier: communiSupplierName,
        hotSale: false,
        enteredCatalogTime: 0,
        isNew: true,
        shownByCompany: true,
        isDigital: false,
        items: [],
        availableItemsCount: 0,
    };
};

export type StoreTableData = {
    productID: string;
    image: ReactElement;
    title: string;
    brand: string;
    price: number | '';
    categoryID: string;
    supplier: string;
    availabilitySupplier: ReactElement;
    hotSale: ReactElement;
    showInStore: ReactElement;
    isForSale: ReactElement;
    enteredCatalogTime: string;
};

export type StoreFilterForm = FilterForm<Product> & {
    categories: { label: string; value: string }[];
    suppliers: { label: string; value: string }[];
    productName: { label: string; value: string } | null;
    productID: { label: string; value: string } | null;
    availabilitySupplier?: { label: string; value: boolean } | null;
    variantSearch?: string | null;
    isShown?: { label: string; value: boolean } | null;
    hotSale?: { label: string; value: boolean } | null;
};

export const defaultStoreFilterForm: StoreFilterForm = {
    categories: [],
    suppliers: [],
    productID: null,
    productName: null,
    availabilitySupplier: null,
    variantSearch: null,
    isShown: null,
    hotSale: null,
    orderBy: {
        direction: 'DESC',
        sortedColumn: 'enteredCatalogTime',
    },
    page: 0,
    eventsPage: 0,
    rowsPerPage: { label: 25, value: 25 },
};

export type StoreCategory = {
    categoryID: string;
    categoryImageURL: string;
    categoryName: string;
    parentCategoryID?: string[];
    parentCategories?: { value: string; label: string }[];
    childrenCategories?: { value: string; label: string }[];
    supplierCategoryID?: string;
    supplierID?: string;
    supplierCategoryName?: string;
    order?: number;
    promotedProduct: boolean;
    company?: { value: string; label: string };
    companiesUsingCategory: { companyID: string }[];
};

export const availability = {
    available: 'זמין למכירה',
    in_stock: 'זמין למכירה',
    not_available: 'לא זמין למכירה',
};

export type Supplier = {
    supplierID: string;
    supplierName: string;
    contactName: string;
    contactEmail: string;
    contactPhone: string;
    APILink: string;
    APIService: string;
    isActive: boolean;
};

export type SupplierForm = {
    supplierID: string;
    supplierName: string;
    contactName: string;
    contactEmail: string;
    contactPhone: string;
    APILink: string;
    APIService: SelectOption;
    isActive: boolean;
};

export type CategoriesForm = FilterForm<StoreCategory> & {
    name: string | null;
    supplier: SelectOption | null;
    parentCategory: SelectOption | null;
    childrenCategory: SelectOption | null;
};

export type CategoriesTableData = {
    categoryID: string;
    categoryName: string;
    supplierName: string;
    parentCategories: string[];
};

export type SupplierTableData = {
    supplierID: string;
    supplierName: string;
    APILink: string;
    contactName: string;
    contactPhone: string;
    contactEmail: string;
    isActive: ReactElement;
};

export const initialSupplier: SupplierForm = {
    APILink: '',
    APIService: { label: '', value: '' },
    contactEmail: '',
    contactName: '',
    contactPhone: '',
    supplierID: v4(),
    supplierName: '',
    isActive: true,
};

export const initialCategories: CategoriesForm = {
    rowsPerPage: { label: 25, value: 25 },
    page: 0,
    eventsPage: 0,
    orderBy: {
        direction: 'DESC',
        sortedColumn: undefined,
    },
    name: null,
    supplier: null,
    parentCategory: null,
    childrenCategory: null,
};

export type SuppliersFilterForm = FilterForm<Supplier> & {
    suppliers: Supplier[];
};

export const defaultCSuppliersValues: SuppliersFilterForm = {
    suppliers: [],
    rowsPerPage: { label: 25, value: 25 },
    page: 0,
    eventsPage: 0,
    orderBy: {
        direction: 'DESC',
        sortedColumn: undefined,
    },
};

export type SupplerApiServices = {
    name: string;
    api: string;
};
