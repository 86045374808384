import React, { useEffect, useState } from 'react';
import { UseFormSetValue } from 'react-hook-form';
import { CategoryForm } from './store.models';

interface CompanyOption {
    value: string;
    label: string;
}

interface SelectedCompany {
    companyID: string;
}

interface PromotedProductModalContentProps {
    companies: CompanyOption[];
    selectedCompanies: SelectedCompany[];
    setSelectedCompanies: React.Dispatch<React.SetStateAction<SelectedCompany[]>>;
    setValue: UseFormSetValue<CategoryForm>;
    t: (key: string) => string;
}

const PromotedProductModalContent: React.FC<PromotedProductModalContentProps> = ({
    companies,
    selectedCompanies,
    setSelectedCompanies,
    setValue,
    t,
}) => {
    const [localSelected, setLocalSelected] = useState<SelectedCompany[]>(selectedCompanies);

    useEffect(() => {
        setLocalSelected(selectedCompanies);
    }, [selectedCompanies]);

    const allSelected = companies.length > 0 && localSelected.length === companies.length;

    const toggleSelectAll = () => {
        const newSelection = allSelected ? [] : companies.map((company) => ({ companyID: company.value }));

        setLocalSelected(newSelection);
        setSelectedCompanies(newSelection);
        setValue('companiesUsingCategory', newSelection, { shouldDirty: true });
    };

    return (
        <div>
            <p>{t('promotedProductWarning')}</p>
            <div style={{ marginBottom: '0.5rem', display: 'flex', alignItems: 'center' }}>
                <input type="checkbox" id="select-all" checked={allSelected} onChange={toggleSelectAll} />
                <label htmlFor="select-all" style={{ marginLeft: '0.5rem' }}>
                    {t('selectAllCompanies')}
                </label>
            </div>
            <div
                style={{
                    maxHeight: '300px',
                    overflowY: 'auto',
                    display: 'grid',
                    gap: '0.5rem',
                    padding: '0.5rem',
                    border: '1px solid #ddd',
                    borderRadius: '5px',
                }}>
                {companies.map((company) => {
                    const checkboxId = `company-${company.value}`;
                    const isChecked = localSelected.some((c) => c.companyID === company.value);
                    return (
                        <div key={company.value} style={{ display: 'flex', gap: '0.5rem' }}>
                            <input
                                type="checkbox"
                                id={checkboxId}
                                name={checkboxId}
                                value={company.value}
                                checked={isChecked}
                                onChange={() => {
                                    setLocalSelected((prev) => {
                                        const alreadySelected = prev.some(
                                            (c) => c.companyID === company.value
                                        );
                                        const updated = alreadySelected
                                            ? prev.filter((c) => c.companyID !== company.value)
                                            : [...prev, { companyID: company.value }];
                                        setSelectedCompanies(updated);
                                        setValue('companiesUsingCategory', updated, { shouldDirty: true });
                                        return updated;
                                    });
                                }}
                            />
                            <label htmlFor={checkboxId}>{company.label}</label>
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

export default PromotedProductModalContent;
